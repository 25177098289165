<template>
  <form-field-text
    v-model="sCode"
    :disabled="disabled"
    :loading="loading"
    :rules="sRule"
    flat
    full-width
    label="currency.rya.code"
    rounded
    solo
  >
    <template #append>
      <v-btn
        :disabled="btnDisabled"
        :loading="!!sCode && loading"
        color="primary"
        depressed
        icon
        outlined
        x-small
        @click="onSave"
      >
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </template>
  </form-field-text>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type { CurrencyRate } from "@planetadeleste/vue-mc-gw";
import { CurrencyRates } from "@planetadeleste/vue-mc-gw";
import type { CurrencyData } from "@planetadeleste/vue-mc-shopaholic";
import { get } from "lodash";

@Component
export default class CompanyCurrencyRyaCode extends Vue {
  @Prop({ type: Object, required: true }) readonly value!: CurrencyData;
  @Prop({ type: Number, required: true }) readonly companyId!: number;

  obCurrencyRate: CurrencyRate | null | undefined = null;
  arCodeList: string[] = [];
  loading = false;

  get sCode() {
    return this.obCurrencyRate
      ? this.obCurrencyRate.get("coderya", null)
      : null;
  }

  set sCode(sValue: string) {
    if (!this.obCurrencyRate) {
      return;
    }

    this.obCurrencyRate.set("coderya", sValue);
  }

  get disabled(): boolean {
    return (
      this.loading ||
      !this.obCurrencyRate ||
      !this.obCurrencyRate.get("editable", true)
    );
  }

  get btnDisabled(): boolean {
    return (
      !this.sCode ||
      !this.obCurrencyRate ||
      !this.obCurrencyRate.isDirty("coderya") ||
      this.arCodeList.includes(this.sCode)
    );
  }

  get sRule() {
    if (!this.arCodeList.length) {
      return "";
    }

    return `excluded:${this.arCodeList.join(",")}`;
  }

  async onSave() {
    if (!this.obCurrencyRate || !this.obCurrencyRate.isDirty("coderya")) {
      return;
    }

    this.loading = true;
    await this.obCurrencyRate.store();
    await this.load();
  }

  async load() {
    this.loading = true;
    const obCollection = new CurrencyRates();
    // @ts-ignore
    await obCollection.filterBy({ companyGrouped: this.companyId }).fetch();

    this.obCurrencyRate = obCollection.find({ currency_id: this.value.id });
    this.arCodeList = obCollection
      .filter(
        (obModel: CurrencyRate) =>
          !!get(obModel, "coderya") &&
          !!this.obCurrencyRate &&
          this.obCurrencyRate.id !== obModel.id
      )
      .map("coderya");
    this.loading = false;
  }

  mounted() {
    this.load();
  }
}
</script>
